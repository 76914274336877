@use "variables" as *;
@use "mixins" as mix;

// ----------------
// Buttons
// ----------------
.primaryBtn {
    @include mix.primaryBtn;

    @media (max-width: $breakpoint-sm) {
        &.vAll {
            &:after {
                margin: 0;
            }

            span {
                display: none;
            }
        }
    }

    &.no-arrow {
        &:after {
            content: none;
        }
    }
}

.secondaryBtn {
    @include mix.secondaryBtn;

    &.no-arrow {
        &:after {
            content: none;
        }
    }
}

.tertiaryBtn {
    @include mix.tertiaryBtn;

    &.no-arrow {
        &:after {
            content: none;
        }
    }
}

.primary-bg {
    background: $primary-color;
}

.secondary-bg {
    background: $secondary-color;
}

.tertiary-bg {
    background: $tertiary-color;
}

.grayGradientBg {
    background: linear-gradient(180deg, #ACACAC 0%, rgba(255, 255, 255, 0.00) 73.44%);
}

.blueGradientBg {
    background: linear-gradient(180deg, $primary-color 0%, rgba(255, 255, 255, 0.00) 57.01%);
}

.top-rounded-radius {
    @include mix.border-radius(25px 25px 0 0);
}

.secondary-bg {
    background: $secondary-color;
}

.rounded-bottom-4 {
    @include mix.border-radius(0px 0px 25px 25px);
}

// Remove this styling when you start on new-project
// main {
//     height: 100vh;
// }
// .sf_logo {
//     width: 250px;
//     height: 100%;
// }
.container {
    width: 95%;
    max-width: 100%;
}

.arrow {
    @include mix.trans;

    &.swiper-prev {
        position: relative;
        top: 3px;
        @include mix.transform(rotate(-180deg));
    }

    &:hover,
    &:focus,
    &:active {
        opacity: 0.5;
    }
}

.arrow-left {
    &:before {
        content: "";
        width: 20px;
        height: 20px;
        background-image: url("/site-assets/svg/black-arrow.svg");
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        margin-right: 5px;
        @include mix.transform(rotate(-180deg));
    }

    &.white {
        &:before {
            filter: brightness(0) invert(1);

        }

        &:hover,
        &:focus,
        &:active {
            color: $base-white;
            text-decoration: underline;
        }
    }
}

// strong {
//     font-weight: 500
// }

.swiper-custom-paginations {

    .swiper-pagination-bullet {
        width: 40px;
        height: 7px;
        opacity: 1;
        background: $bg-light;
        margin: 0 0.325rem;
        cursor: pointer;
        @include mix.border-radius(5px);
        @include mix.trans;

        &.swiper-pagination-bullet-active {
            background: $primary-color;
        }

        &:hover,
        &:focus,
        &:active {
            background: $primary-color;
        }
    }

}

.formio-component {
    .formio-component-submit {
        text-align: right;
    }

    .formio-component {
        margin-bottom: 1rem;

        .fileSelector {
            @include mix.border-radius(25px);
        }

        .formio-choices {
            @include mix.border-radius(25px);

            .form-control {
                padding: 0.75rem 1rem;
            }
        }

        .form-control {
            @include mix.border-radius(25px);
        }

        input {
            border: none;
            @include mix.border-radius(25px);
            &:not(.form-check-input) {
                padding: 0.875rem 1.2rem;
            }
        }

        textarea {
            border: 1px solid #cccccc;
        }

        .btn {
            @include mix.primaryBtn;
            border: none;
            margin: 0;

            &:after {
                content: none;
            }
        }

        .required {
            &:before {
                content: "* Required";
                opacity: 0.3;
                font-size: 0.8rem;
            }
        }

        .browse {
            font-weight: 500;
        }

        a {

            &:hover,
            &:focus,
            &:active {
                color: $font-color;
            }
        }

        .list-group-item {
            border-color: $bg-light;
        }

    }
}

.consultant-pop-content {
    &.fancybox__content {
        background: $base-black;
        color: $base-white;
        border: 4px solid $base-black;
        max-width: 1000px;

        .card-img {
            @include mix.object-fit;
        }

        .post-title {
            color: $primary-color;
        }

        .contacts {
            .nav-item {
                a {
                    margin: 0 0.5rem 0.5rem 0;
                    padding: 0.55rem 0.725rem;
                    background: $bg-light;
                    @include mix.border-radius(50px);
                    @include mix.trans;

                    svg {
                        width: 15px;
                        height: 15px;

                        path {
                            fill: $base-black;
                        }
                    }

                    &:hover,
                    &:active {
                        opacity: 0.5;
                    }
                }
            }
        }

        .f-button {
            opacity: 1 !important;
            top: 5px !important;
            right: 5px !important;
            background: $primary-color;
            color: $base-black;
            @include mix.border-radius(100%);

            &:hover,
            &:focus,
            &:active {
                opacity: 0.5 !important;
                color: $base-black;
                background: $primary-color;
            }

        }
    }
}

.dialog-content {
    &.fancybox__content {
        @include mix.border-radius(25px);
        width: 95%;

        @media (min-width: $breakpoint-md) {
            width: 50%;
            max-width: 450px;
        }

    }

    .formio-component {
        .formio-component {
            input {
                border: 1px solid $bg-light;
            }

            textarea {
                border: 1px solid $bg-light;
            }
        }
    }
}

.footer-navigations-2 {
    border-top: 1px solid #dfdfdf;
}

.aboutUsContact {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 72%;
    margin-top: 32px;

    @media (max-width: $breakpoint-md) {
        display: block;
        margin: 0px auto;
        align-items: center;
        width: 100%;
    }

}

.browse {
    color: white;
    text-decoration: underline;
}

